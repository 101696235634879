import ListActions from "modules/list/actions";
import { AuditSchema } from "utils/schemas";
import api from "services/api";
import store from "services/store";
import { getCurrentContext } from "state/auth/selectors/common";
import moment from "moment";

import { auditFetcher, modalService } from "./services";

function fetchAuditLogs(query) {
  const currentContext = getCurrentContext(store.getState());
  const [startTime, endTime] = query.rangePicker || [];

  let filters = {
    continue: query.continue,
    startTime: moment(startTime).startOf("day").format(),
    endTime: moment(endTime).endOf("day").format(),
    ...(query.actionType !== "all" && { actionType: query.actionType }),
    ...(query.userUid !== "all" && { userUid: query.userUid }),
    ...(query.resourceUid !== "all" && { resourceUid: query.resourceUid }),
    ...(query.resourceKind !== "all" && { resourceKind: query.resourceKind }),
    ...(query.projectUid &&
      query.projectUid !== "all" && { projectUid: query.projectUid }),
  };

  if (!currentContext.isAdmin) {
    filters = { ...filters, projectUid: currentContext.projectUid };
  }

  return api.get(`v1/audits`, filters);
}

export const auditListActions = new ListActions({
  schema: [AuditSchema],
  fetchData(query) {
    return fetchAuditLogs(query);
  },
  initialQuery() {
    return {
      resourceKind: "all",
      projectUid: "all",
      resourceUid: "all",
      actionType: "all",
      userUid: "all",
      rangePicker: [moment().subtract(1, "months"), moment()],
      limit: 50,
    };
  },
});

export function fetchAllResourcesNames(projectId, resourceKind) {
  return async function thunk(dispatch) {
    const urlRequest = {
      spectrocluster: "v1/spectroclusters",
      clusterprofile: "v1/clusterprofiles",
      location: "v1/users/assets/locations",
      sshkey: "v1/users/assets/sshkeys",
      workspace: "v1/dashboard/workspaces",
      cloudaccount: "v1/cloudaccounts/summary",
    };

    let result = await api.get(urlRequest[resourceKind], null, {
      headers: { ProjectUid: projectId },
    });

    dispatch({ type: "FETCH_RESOURCE_NAMES", resources: result.items });
  };
}

export function openAuditDetailsModal(auditUid) {
  return function thunk(dispatch) {
    modalService.open({ auditUid });
    dispatch(auditFetcher.fetch());
  };
}

export function onFiltersChange(name, value) {
  return function thunk(dispatch, getState) {
    const module = "auditlogs";

    dispatch(auditListActions.changeQuery({ name, value, module }));

    const state = getState();
    const currentContext = getCurrentContext(state);
    const projectId = currentContext.isAdmin
      ? state.list[module].query.projectUid
      : currentContext.projectUid;
    const resourceKind = state.list[module].query.resourceKind;

    if (projectId !== "all" && resourceKind !== "all") {
      dispatch(
        auditListActions.changeQuery({
          name: "resourceUid",
          value: "all",
          module,
        })
      );

      return dispatch(fetchAllResourcesNames(projectId, resourceKind));
    }

    dispatch({
      type: "DISPLAY_RESOURCE_NAMES_DROPDOWN",
      value: false,
    });
    dispatch(
      auditListActions.changeQuery({
        name: "resourceUid",
        value: "all",
        module,
      })
    );
  };
}
