import React from "react";
import i18next from "i18next";
import moment from "moment";
import Url from "url";

import store from "services/store";
import {
  getAllPermissions,
  getCurrentTenantUid,
  getCurrentUser,
  getUserContexts,
  getUserMenu,
  getUserProjects,
  hasTenantLevelClusterGroup,
  isOnPremApplication,
  isSecurityModeFips,
} from "state/auth/selectors";
import createFormActions from "modules/form/actions";

import permissionsService from "services/permissions";
import api, { nonProjectApi } from "services/api";
import loginService from "services/loginService";
import {
  contextPreferenceCache,
  backToProjectCache,
  includeMastersCache,
  devModeProductTour,
} from "services/localstorage/cache";
import Validator from "services/validator";
import { EmailType, Missing } from "services/validator/rules";
import history from "services/history";
import notifications from "services/notifications";

import {
  UserSchema,
  ProjectSchema,
  ProjectPermissionsSchema,
} from "utils/schemas";
import { FLAGS } from "utils/constants/flags";
import permissions from "services/permissions";
import {
  customerFetcher,
  subscriptionFetcher,
} from "state/plandetails/services";
import flags from "services/flags";
import {
  initializeFullStory,
  syncFullStoryWithUser,
  terminateFullstorySession,
} from "utils/fullstory";
import Button from "components/ui/Button";
import { Trans } from "react-i18next";
import createApi from "services/api/create";
import { appRefreshModalService, forgetPasswordActions } from "./services";
import appEnv from "services/app";
import { openProductTourModal } from "state/productTour/actions";
import dataFetcher from "modules/dataFetcher";
import { oidcOrgFetcher, ssoLoginsFetcher } from "state/sso/providers/services";
import { clusterGroupsFetcher } from "state/clustergroups/services/listing";
import { developerQuotaUsageFetcher } from "state/devnestedcluster/services";
import * as ROUTES from "utils/constants/routes";
import { generatePath } from "react-router";
import { get } from "lodash";
import { ADMIN_GUID } from "utils/constants";
import { getCurrentContext } from "./selectors/common";

const loginValidator = new Validator();
loginValidator.addRule(["emailId", "password"], Missing());
loginValidator.addRule(["emailId"], EmailType());

export const orgsFetcher = dataFetcher({
  keepStale: true,
  selectors: ["auth", "organizations"],
  async fetchData() {
    const data = await api.get(`v1/auth/orgs`);
    return data.organizations;
  },
});

export const loginActions = createFormActions({
  validator: loginValidator,
  init: () => {
    const { orgName } = store.getState().auth.currentOrganization;

    return Promise.resolve({
      emailId: "",
      password: "",
      org: orgName !== "root" ? orgName : undefined,
    });
  },
  submit: async function (data) {
    const oidcToken = store.getState().location.params.token;
    if (oidcToken) {
      const response = await api.post(
        `v1/oidc/authenticate/${oidcToken}`,
        data
      );
      if (response?.redirectUrl) {
        window.location = response.redirectUrl;
      } else {
        history.push("/auth/oidc/success");
      }

      return;
    }

    try {
      await loginService.login(data);
    } catch (e) {
      if (e.code === "TenantPlanExpired") {
        return history.push(`/auth-error?message=${e.message}&code=${e.code}`);
      }

      if (e.code === "UserPasswordExpired") {
        return history.push("/auth/password-update");
      }

      throw e;
    }
    const state = store.getState();
    notifications.close("password-reset-success");

    const { orgName } = store.getState().auth.currentOrganization;

    if (orgName === "root") {
      try {
        await store.dispatch(orgsFetcher.fetch());

        const organizations = await orgsFetcher.selector(store.getState())
          .result;
        if (organizations.length > 1) {
          history.push("/auth/orgs");
          return;
        }
      } catch (err) {}
    }

    const query = history.getQuery();
    const orgs = state.auth.orgs;
    const selectedOrg = orgs.find((org) => org.orgName === data.org);
    let redirectUrl = selectedOrg?.redirectUrl || window.location.origin;
    let returnTo = query.returnTo !== "/auth" ? query.returnTo || "" : "";
    if (!returnTo.startsWith("/")) {
      returnTo = "";
    }

    if (query?.forcePassword) {
      window.location.href = window.location.origin;
      return;
    }

    window.location.href = returnTo ? `${redirectUrl}${returnTo}` : redirectUrl;
  },
});

const setPasswordValidator = new Validator();
setPasswordValidator.addRule("confirmPassword", (value, key, data) => {
  if (data.newPassword !== data.confirmPassword) {
    return i18next.t("Passwords do not match");
  }
});

export const setPasswordFormActions = createFormActions({
  init: () => Promise.resolve({ newPassword: "", confirmPassword: "" }),
  validator: setPasswordValidator,
  submit: async function (data) {
    const { passwordToken, action } = store.getState().location.params;
    try {
      await api.patch(`v1/auth/password/${passwordToken}/${action}`, {
        password: data.newPassword,
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while reseting the password"),
        description: err.message,
      });
      return;
    }
    history.push("/auth");
    notifications.success({
      message: i18next.t(
        "Password changed successfully, please login using your new password"
      ),
      duration: null,
      key: "password-reset-success",
    });
  },
});

const registerValidator = new Validator();
registerValidator.addRule(["email", "orgName"], Missing());
registerValidator.addRule(["email"], EmailType());
registerValidator.addRule(["orgName"], (value) =>
  value.match(/[^a-z|^A-Z|^-|^\d]/g)
    ? i18next.t("Invalid organization name")
    : false
);
export const registerFormActions = createFormActions({
  init: () => {
    const { route } = store.getState().location;

    return Promise.resolve({
      email: "",
      firstName: "",
      lastName: "",
      orgName: "",
      loginMode: route === "AUTH.DEVX_SIGNUP" ? "dev" : "devops",
    });
  },
  validator: registerValidator,
  submit: async function (data) {
    const registerApi = createApi({
      // NOTE: for dev use "https://fw2t4gm0ih.execute-api.us-east-1.amazonaws.com/dev"
      baseUrl: appEnv.env.SIGNUP_URL,
    });
    const promise = registerApi.post("api/tenants", {
      email: data.email,
      first_name: " ",
      last_name: " ",
      org_name: data.orgName,
      loginMode: data.loginMode,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while creating the account"),
        description: err?.result?.message,
      });

      return promise;
    }
  },
});

export function redeemTenantToken() {
  return async function thunk(dispatch, getState) {
    const locationParams = getState().location.params;
    const query = history.getQuery();
    if (!query.isTenantAdmin) {
      return;
    }
    const promise = api.post(
      `v1/tenants/signup/${locationParams.passwordToken}/activate`
    );
    dispatch({ type: "REDEEM_TENANT_TOKEN", promise });
    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t("Unable to activate this tenant"),
        duration: null,
        description: err.message,
      });
      throw err;
    }
  };
}

export function setPermissions() {
  return function thunk(_, getState) {
    const permissions = getAllPermissions(getState());
    permissionsService.init(permissions);
  };
}

function updatePreferredContext() {
  return function (_, getState) {
    const currentContext = getCurrentContext(getState());
    const currentUser = getCurrentUser(getState());

    if (currentContext?.isAdmin) {
      contextPreferenceCache.set(currentUser.metadata.uid, currentContext.guid);
      return;
    }

    contextPreferenceCache.set(
      currentUser.metadata.uid,
      currentContext.projectUid
    );
  };
}

export function setCurrentProject(value) {
  return function thunk(dispatch) {
    dispatch({ type: "APP_LOADING", isLoading: true });
    dispatch({ type: "SET_CURRENT_PROJECT", currentProjectId: value });

    dispatch(setPermissions());
    dispatch({ type: "APP_LOADING", isLoading: false });
  };
}

export function redirectToDefaultRoute(resetToDefault) {
  return function thunk(_, getState) {
    const menu = getUserMenu(getState());
    if (!menu[0]?.path) {
      history.push("/no-projects");
    }

    const { tab = "", id = "", uid = "" } = getState().location?.params;
    const currentRoute = get(ROUTES, getState().location?.route);

    const menuRouteCorespondingToLocation = menu.find((route) => {
      const root = Url.parse(route.path).pathname.split("/")[1];

      return history.location.pathname.includes(root);
    });

    const forwardPath =
      id || uid
        ? menuRouteCorespondingToLocation?.path
        : generatePath(currentRoute, { tab });

    if (forwardPath && forwardPath !== ROUTES.ROOT && !resetToDefault) {
      return history.push(forwardPath);
    }

    history.push(menu[0]?.path);
  };
}

function backupBackToProject() {
  return function thunk(dispatch, getState) {
    const currentContext = getCurrentContext(getState());
    if (!currentContext) {
      return null;
    }

    if (currentContext.projectUid) {
      const currentUser = getCurrentUser(getState());
      backToProjectCache.set(
        currentUser.metadata.uid,
        currentContext.projectUid
      );

      dispatch({
        type: "SET_BACK_TO_PROJECT",
        projectUid: currentContext.guid,
      });
    }
  };
}

export function updateLoginMode({ loginMode }) {
  return async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const promise = api.patch(
      `v1/users/${currentUser?.metadata?.uid}/status/loginMode`,
      {
        loginMode,
      }
    );

    dispatch({
      type: "UPDATE_LOGIN_MODE",
      promise: promise.then(() => ({ ...currentUser, status: { loginMode } })),
      schema: UserSchema,
    });

    return promise;
  };
}

export function toggleDevMode({
  shouldResetToDefault = false,
  shouldUpdateLoginMode = true,
} = {}) {
  return async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const currentLoginMode = currentUser?.status?.loginMode;

    const tenantLevelClusterGroup = hasTenantLevelClusterGroup(getState());

    if (shouldUpdateLoginMode) {
      const loginMode = currentLoginMode === "dev" ? "devops" : "dev";

      await dispatch(updateLoginMode({ loginMode }));
      dispatch({ type: "TOGGLE_DEV_MODE" });

      if (getState().auth.devMode) {
        if (tenantLevelClusterGroup) {
          dispatch(developerQuotaUsageFetcher.fetch("tenant"));
        } else {
          dispatch(developerQuotaUsageFetcher.fetch("system"));
        }
      }
    }

    const projects = getUserProjects(getState());
    const context = getCurrentContext(getState());

    if (context.isAdmin && projects.length > 0) {
      dispatch(setCurrentProject(projects[0].guid));
    }

    dispatch(updatePreferredContext());
    if (shouldResetToDefault) {
      dispatch(redirectToDefaultRoute(shouldResetToDefault));
    }

    if (!devModeProductTour.get("visited") && getState().auth.devMode) {
      dispatch(openProductTourModal());
    }

    if (!!isSecurityModeFips(getState()) && getState().auth.devMode) {
      notifications.warn({
        message: i18next.t("App Mode is a non-FIPS compliant feature"),
      });
    }
  };
}

export function selectProject(value) {
  return async function thunk(dispatch, getState) {
    if (value === ADMIN_GUID) {
      const currentLoginMode = getCurrentUser(getState())?.status?.loginMode;
      dispatch(backupBackToProject());
      dispatch(getTenantPlan());
      if (currentLoginMode === "dev") {
        await dispatch(updateLoginMode({ loginMode: "devops" }));
        dispatch({ type: "UPDATE_DEV_MODE", devMode: false });
      }
    }

    const currentContext = getState().auth.currentProjectId;
    let shouldResetToDefault = false;
    if (value === ADMIN_GUID || currentContext === ADMIN_GUID) {
      shouldResetToDefault = value !== currentContext;
    }

    dispatch(setCurrentProject(value));
    dispatch(updatePreferredContext());
    dispatch(redirectToDefaultRoute(shouldResetToDefault));
  };
}

export function selectProjectByUid(value) {
  return function thunk(dispatch, getState) {
    const userContexts = getUserContexts(getState());
    const project = userContexts.find(
      (context) => context.projectUid === value
    );

    dispatch(selectProject(project.guid));
  };
}

export function selectProjectFromRoute(params) {
  const projectUid = params.projectUid;
  return function thunk(dispatch, getState) {
    const userContexts = getUserContexts(getState());
    const project = userContexts.find(
      (context) => context.projectUid === projectUid
    );

    if (!project) {
      return;
    }

    if (project.guid === ADMIN_GUID) {
      dispatch(backupBackToProject());
    }

    dispatch(setCurrentProject(project.guid));
  };
}

export function fetchCurrentUser() {
  return async function thunk(dispatch) {
    const promise = nonProjectApi.get("v1/users/me");

    dispatch({
      promise: promise,
      type: "FETCH_CURRENT_USER",
      schema: UserSchema,
    });

    const currentUser = await promise;
    const projectPermissions = currentUser?.status?.projectPermissions || {};

    dispatch({
      data: Object.keys(projectPermissions).map((key) => ({
        projectUid: key,
        permissions: projectPermissions[key],
      })),
      schema: [ProjectPermissionsSchema],
      type: "SET_USER_PROJECTS",
    });

    return promise;
  };
}

export function fetchUserProjects(continueToken) {
  return async function thunk(dispatch) {
    const promise = api.get(`v1/dashboard/projects/metadata`, {
      continue: continueToken,
    });

    dispatch({
      promise,
      type: "FETCH_USER_PROJECTS",
      schema: { items: [ProjectSchema] },
    });

    const response = await promise;

    if (response?.listmeta?.continue) {
      dispatch(fetchUserProjects(response.listmeta.continue));
    }
  };
}

export const projectsMetadataFetcher = dataFetcher({
  selectors: ["projects", "metadata"],
  schema: [ProjectPermissionsSchema],
  async fetchData() {
    const response = await api.get("v1/dashboard/projects/metadata");
    return response.items.map((project) => ({
      ...project,
      projectUid: project.metadata.uid,
    }));
  },
});

export function fetchUserContext() {
  return async function (dispatch) {
    await dispatch(fetchCurrentUser());
    await dispatch(fetchUserProjects());
    await dispatch(projectsMetadataFetcher.fetch());
  };
}

function restoreBackToProject() {
  return function thunk(dispatch, getState) {
    const currentUser = getCurrentUser(getState());
    let backToProject = backToProjectCache.get(currentUser.metadata.uid);
    const contexts = getUserContexts(getState());
    backToProject = contexts.find(
      (context) => context.projectUid === backToProject
    );

    if (backToProject) {
      dispatch({
        type: "SET_BACK_TO_PROJECT",
        projectUid: backToProject.guid,
      });
    }
  };
}

function setFallbackProject() {
  return function (dispatch, getState) {
    const contexts = getUserContexts(getState());
    const projects = getUserProjects(getState());
    const admin = contexts.find((item) => item.guid === ADMIN_GUID);

    if (projects.length !== 0) {
      dispatch(setCurrentProject(projects[0].guid));
      return;
    }

    if (admin) {
      dispatch(setCurrentProject(admin?.guid));
      return;
    }

    dispatch({ type: "APP_LOADING", isLoading: false });
  };
}

export const freemiumFetcher = dataFetcher({
  selectors: ["freemium", (state) => getCurrentTenantUid(state)],
  async fetchData([_, tenantUid]) {
    try {
      return await api.get(`v1/tenants/${tenantUid}/freemiumUsage`);
    } catch (e) {}
  },
});

export const cloudCreditFetcher = dataFetcher({
  selectors: ["cloudCredit", (state) => getCurrentTenantUid(state)],
  async fetchData([_, tenantUid]) {
    try {
      return await api.get(`v1/tenants/${tenantUid}/creditAccount/aws`);
    } catch (e) {}
  },
});

function getAppFeatures() {
  return async function thunk(dispatch) {
    const promise = api.get(`v1/system/appFeatures`);

    try {
      await promise;

      dispatch({
        type: "FETCH_APP_FEATURES",
        promise,
      });
    } catch (e) {}

    return promise;
  };
}

export function initAuthApp() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: "APP_LOADING", isLoading: true });
    await appEnv.initialize();
    const orgName = getState()?.auth?.currentOrganization?.orgName;
    dispatch(ssoLoginsFetcher.fetch(orgName));
    if (getState().location.params.token) {
      await dispatch(oidcOrgFetcher.fetch());
    }
    dispatch({ type: "APP_LOADING", isLoading: false });
  };
}

export function initOidcFlow() {
  const api = createApi();
  return async function thunk(dispatch, getState) {
    dispatch({ type: "APP_LOADING", isLoading: true });
    dispatch(fetchTenantInfo());
    dispatch({
      type: "SET_LOGIN_STEP",
      step: "login",
    });
    const promise = api.get("v1/users/me");
    try {
      await promise;
    } catch (e) {
      dispatch({ type: "APP_LOADING", isLoading: false });
      dispatch(initAuthApp());
      return;
    }

    await loginService.refreshToken();
    loginService.startWatchingEvents();
    const token = getState().location.params.token;
    try {
      const response = await api.post(`v1/oidc/refresh/${token}`, {
        token: loginService.getAuthorizationToken(),
      });
      if (response?.redirectUrl) {
        window.location = response.redirectUrl;
        return;
      }
    } catch (err) {
      history.push(`/auth-error?message=OIDC Token is invalid`);
      dispatch({ type: "APP_LOADING", isLoading: false });
      return;
    }

    history.push(`/auth/oidc/success`);
  };
}

export function initApp() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: "APP_LOADING", isLoading: true });
    loginService.refreshToken();
    loginService.startWatchingEvents();

    try {
      try {
        await dispatch(fetchUserContext());
        await dispatch(fetchOrganization({ bypassError: true }));
      } catch (err) {}
      try {
        await appEnv.initialize();
      } catch (err) {}
      dispatch(orgsFetcher.fetch());
      const contexts = getUserContexts(getState());
      const currentUser = getCurrentUser(getState());
      if (currentUser) {
        initializeFullStory();
        syncFullStoryWithUser(currentUser);
      }
      const tenantOrg = currentUser?.status?.tenant?.orgName;
      const orgName = getState().auth.currentOrganization?.orgName;

      let preferredContext = contextPreferenceCache.get(
        currentUser.metadata.uid
      );

      if (orgName !== "root" && orgName !== tenantOrg) {
        // dispatch(logout());
      }

      // TODO maybe we should have a single way to restore a users settings
      if (includeMastersCache.get(currentUser.metadata.uid)) {
        dispatch({
          type: "TOGGLE_GAUGE_METRICS",
        });
      }

      dispatch(restoreBackToProject());

      preferredContext = contexts.find(
        (context) =>
          context.projectUid === preferredContext ||
          context.guid === preferredContext
      );

      if (!preferredContext) {
        dispatch(setFallbackProject());
      }

      if (preferredContext) {
        dispatch(setCurrentProject(preferredContext.guid));
      }
      // dev mode quota
      await dispatch(clusterGroupsFetcher.fetch());
      const tenantLevelClusterGroup = hasTenantLevelClusterGroup(getState());

      if (tenantLevelClusterGroup) {
        dispatch(developerQuotaUsageFetcher.fetch("tenant"));
      } else {
        dispatch(developerQuotaUsageFetcher.fetch("system"));
      }

      if (currentUser?.status?.loginMode === "dev") {
        dispatch(toggleDevMode({ shouldUpdateLoginMode: false }));
        dispatch({ type: "UPDATE_DEV_MODE", devMode: true });
      }

      dispatch(setPermissions());

      await dispatch(getAppFeatures());

      if (!isOnPremApplication(store.getState())) {
        const appFeatures = getState().auth?.appFeatures;

        if (appFeatures?.freemium?.isFreemium) {
          try {
            await dispatch(freemiumFetcher.fetch());
          } catch (e) {}
        }

        if (appFeatures?.freeCloudCredit) {
          dispatch(cloudCreditFetcher.fetch());
        }
      }

      if (permissions.isAdmin && flags.has(FLAGS.BILLING)) {
        const plan = await dispatch(getTenantPlan());
        if (!plan?.spec?.type) {
          return;
        }

        if (plan.spec.type !== "Trial") {
          dispatch(customerFetcher.fetch());
          dispatch(subscriptionFetcher.fetch());
        } else {
          const expiryMoment = plan?.spec?.expiry
            ? moment(plan?.spec?.expiry)
            : null;

          if (!expiryMoment) {
            return;
          }

          const expiresSoon = expiryMoment.diff(moment(), "days") < 8;

          if (expiresSoon) {
            notifications.open({
              key: "upgradePlan",
              message: (
                <div>
                  <Trans>
                    <div>
                      Your trial plan is expiring on{" "}
                      {expiryMoment.format("MMM DD YYYY")}
                    </div>
                    <br />
                    <div>
                      To continue using the Spectrocloud features please upgrade
                      your plan
                    </div>
                    <br />
                    <Button
                      data-qa="upgrade-plan"
                      onClick={() => {
                        history.push("/admin/settings/plandetails");
                        notifications.close("upgradePlan");
                      }}
                    >
                      Upgrade now
                    </Button>
                  </Trans>
                </div>
              ),
            });
          }
        }
      }
      dispatch({ type: "APP_LOADING", isLoading: false });
    } catch (e) {
      dispatch({ type: "APP_LOADING", isLoading: false });
    }
  };
}

export function fetchOrganization({ bypassError = false } = {}) {
  return async (dispatch) => {
    const query = history.getQuery();
    const { orgName = "" } = query;
    const promise = api.get(
      `v1/auth/org${orgName ? `?orgName=${orgName}` : ""}`
    );

    dispatch({
      type: "FETCH_TENANT_ORG",
      promise,
    });

    let response;
    try {
      response = await promise;
    } catch (err) {
      dispatch({ type: "APP_LOADING", isLoading: false });
      if (err.code === "OrganizationNotFound") {
        history.push("/404");
      }

      const query = history.getQuery();

      if (query.forcePassword || bypassError) {
        return;
      }

      return history.push(
        `/auth-error?message=${err?.message}&code=${err?.code}`
      );
    }

    if (
      ["quick-start", "enterprise", "enterprise-cli", "airgap"].includes(
        response?.appEnv
      ) &&
      !response.totalTenants
    ) {
      window.location.href = `${window.location.origin}/system`;
    }

    return response;
  };
}

export function fetchTenantInfo() {
  return async function thunk(dispatch, getState) {
    const location = window.location;
    if (!location) {
      return;
    }
    const response = await dispatch(fetchOrganization());

    dispatch(loginActions.init({ module: "login" }));

    if (!response) {
      return;
    }

    if (response.appEnv === "quick-start") {
      const migrationStatusFetcher = api.get(`v1/installers/spectro/status`);
      dispatch({
        type: "FETCH_QUICKSTART_MIGRATION_STATUS",
        promise: migrationStatusFetcher,
      });

      try {
        await migrationStatusFetcher;
      } catch (e) {
        dispatch({ type: "APP_LOADING", isLoading: false });
      }
    }
  };
}

export function onForgotPassword() {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const step = state.auth.step;
    const steps = ["resetPassword", "resetConfirm"];
    const stepIndex = steps.indexOf(step);

    if (step === "resetPassword") {
      try {
        await dispatch(
          forgetPasswordActions.submit({ module: "forgotPassword" })
        );
      } catch (err) {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: err?.message,
        });

        return;
      }
    }

    dispatch({
      type: "SET_LOGIN_STEP",
      step: steps[stepIndex + 1],
    });
  };
}

export function logout() {
  return async function thunk(dispatch) {
    const data = await loginService.logout();

    terminateFullstorySession();
    dispatch({ type: "USER_LOGOUT" });

    if (data?.redirectUrl) {
      window.location.href = data.redirectUrl;
      return;
    }

    history.push("/auth");
  };
}

export function resetPassword() {
  return async function (dispatch, getState) {
    const redirectUrl = getState().auth.currentOrganization?.redirectUrl || "/";
    try {
      const promise = api.post("v1/auth/user/password/reset", {
        emailId: getState().forms.forgotPassword.data.emailId,
      });

      dispatch({
        type: "SET_REDIRECT_URL",
        url: redirectUrl,
      });

      await promise;
    } catch (err) {}
  };
}

export function acceptTermsAndAgreement() {
  return async function thunk(dispatch, getState) {
    const currentUser = getCurrentUser(getState());

    try {
      await api.patch(
        `v1/tenants/${currentUser.status.tenant.tenantUid}/contract/accept`
      );
      dispatch(fetchCurrentUser());
      dispatch(openProductTourModal({ onboarding: true }));
    } catch (err) {
      notifications.error({
        details: err.message,
        message: i18next.t("Unable to accept the contract."),
      });
    }
  };
}

export function setAuthStep(step) {
  return (dispatch, getState) => {
    store.dispatch({
      type: "SET_LOGIN_STEP",
      step,
    });
  };
}

export function getTenantPlan() {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;
    const promise = nonProjectApi.get(`v1/tenants/${tenantUid}/plan`);

    dispatch({
      type: "GET_TENANT_PLAN",
      promise,
    });
    return promise;
  };
}

export function fetchBannerMessage() {
  return async (dispatch, getState) => {
    const orgName = getState()?.auth?.currentOrganization?.orgName;

    const api = createApi();
    if (orgName && orgName !== "root") {
      const tenantPromise = api.get(`v1/auth/org/${orgName}/loginBanner`);
      dispatch({
        type: "GET_TENANT_BANNER",
        promise: tenantPromise,
      });
      try {
        await tenantPromise;
      } catch (e) {
        dispatch({ type: "APP_LOADING", isLoading: false });
      }
    }

    const rootPromise = api.get(`v1/auth/system/loginBanner`);
    dispatch({
      type: "GET_SYSTEM_BANNER",
      promise: rootPromise,
    });
    try {
      await rootPromise;
    } catch (e) {
      dispatch({ type: "APP_LOADING", isLoading: false });
    }
  };
}

// PASSWORD EXPIRED CHANGE

const passwordValidator = new Validator();
passwordValidator.addRule("newPassword", (value, key, data) => {
  if (value === data.currentPassword) {
    return i18next.t("New password must be different from your current one");
  }
});
passwordValidator.addRule("confirmPassword", (value, key, data) => {
  if (data.newPassword !== "" && value !== data.newPassword) {
    return i18next.t("Please make sure your passwords match");
  }
});
passwordValidator.addRule(["currentPassword", "newPassword"], Missing());

export const passwordFormActions = createFormActions({
  validator: passwordValidator,
  init() {
    return Promise.resolve({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  },
  async submit(data) {
    try {
      await api.patch(`v1/users/password/change`, {
        emailId: store.getState().forms.login.data.emailId,
        newPassword: data.newPassword,
        currentPassword: data.currentPassword,
      });

      notifications.success({
        message: i18next.t("Your password has been updated successfully."),
      });

      store.dispatch({
        type: "SET_LOGIN_STEP",
        step: "login",
      });

      history.push("/auth");
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while updating your password."
        ),
        description: err.message,
      });
    }
  },
});

export function openAppRefreshModalService() {
  return (dispatch, getState) => {
    appRefreshModalService.open();
  };
}
