import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { css } from "styled-components";
import { Layout, Tooltip } from "antd";
import i18n from "i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

import {
  white,
  black,
  winterMist,
  gray,
  darkGray,
  regentGray,
} from "utils/constants/colors";
import Fields from "components/ui/Fields";
import Icon from "components/ui/Icon";
import { FieldWrap } from "components/styled/Field";
import { FLAGS } from "utils/constants/flags";
import flags from "services/flags";
import { useFormContext } from "modules/form";
import * as colors from "utils/constants/colors";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { createTeleporter } from "react-teleporter";

const StyledTrigger = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid ${winterMist};
  height: 100%;
  width: 100%;
`;

const TriggerTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin: 0 12px;
  color: ${black};
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  border-radius: 19px;
  width: 26px;
  height: 26px;
  margin-left: 17px;

  svg {
    transition: all 0.3s ease-in-out;
    font-size: 10px;
    ${(props) =>
      props.expanded &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const StyledSider = styled(Layout.Sider)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: ${white};
  color: ${gray};
  box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 0px;
  padding: 48px 0 0 0;
  overflow: hidden;

  &.ant-layout-sider-collapsed {
    bottom: 100%;
    .ant-layout-sider-children {
      display: none;
    }
  }

  .ant-layout-sider-trigger {
    position: absolute;
    top: 0;
    height: 48px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
`;

const Group = styled.div`
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 1px 16px;

  .ant-radio-group {
    display: flex;
    flex-direction: column;

    > label {
      font-size: 14px;
      height: 22px;
      line-height: 22px;
      margin-bottom: 8px;
      text-transform: capitalize;
    }
  }
`;

const FormWrap = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const UbuntuFormWrap = styled.div`
  padding: 24px 16px;

  > ${FieldWrap} {
    margin: 8px 0;
  }
`;

const InfoBoxWrap = styled.div`
  color: ${darkGray};
  background: #dcf1fe;
  padding: 16px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  > ul {
    margin: 16px 0;
    padding-left: 24px;
  }
`;

const SwitchFieldsWrap = styled.div`
  margin-top: 24px;
`;

const SwitchFieldWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  margin-bottom: 1px;

  > ${FieldWrap} {
    margin: 0;
    width: auto;
  }
`;

const StyledInfoIcon = styled(Icon)`
  font-size: 14px;
  color: ${regentGray};
  cursor: pointer;
  margin-left: 8px;
`;

const ErrorIcon = styled(Icon)`
  color: ${colors.yellow};
  font-size: 15px;
  margin-right: 5px;
`;

const StyledError = styled(`span`)`
  margin-left: 8px;
`;

const UBUNTU_ADVANTAGE_OPTIONS = [
  {
    name: "esm-infra",
    // info: "esm-infra info tooltip",
    getLabel: () => i18n.t("esm-infra"),
  },
  {
    name: "esm-apps",
    // info: "esm-infra info tooltip",
    getLabel: () => i18n.t("esm-apps"),
  },
  {
    name: "fips",
    // info: "fips info tooltip",
    getLabel: () => i18n.t("fips"),
  },
  {
    name: "fips-updates",
    // info: "fips-updates info tooltip",
    getLabel: () => i18n.t("fips-updates"),
  },
  {
    name: "livepatch",
    // info: "livepatch info tooltip",
    getLabel: () => i18n.t("livepatch"),
  },
  {
    name: "cis",
    // info: "cis info tooltip",
    getLabel: () => i18n.t("cis"),
  },
  {
    name: "cc-eal",
    // info: "cc-eal info tooltip",
    getLabel: () => i18n.t("cc-eal"),
  },
  {
    name: "usg",
    // info: "cc-eal info tooltip",
    getLabel: () => i18n.t("usg"),
  },
];

function UbuntuAdvantageForm({
  presetsPathName,
  onChange,
  disabledFields = [],
}) {
  const { t } = useTranslation();
  const { fieldErrors } = useFormContext();

  const getPathName = (path) => {
    return `${presetsPathName}.ubuntuAdvantage.${path}`;
  };

  const renderErrorIcon = () => (
    <StyledError>
      <ErrorIcon awesome={faExclamationCircle} />
    </StyledError>
  );

  const renderInfoBox = () => (
    <InfoBoxWrap>
      <Trans>
        <div>Benefits with Ubuntu Pro:</div>
        <ul>
          <li>Extended Security Maintenance</li>
          <li>Kernel Livepatch service to avoid reboots</li>
          <li>FIPS 140-2 Level 1 certified crypto modules</li>
          <li>Common Criteria EAL2</li>
        </ul>
        <span>Free personal token for up to 5 machines</span>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ubuntu.com/pro"
          >
            https://ubuntu.com/pro
          </a>
        </div>
      </Trans>
    </InfoBoxWrap>
  );

  return (
    <UbuntuFormWrap>
      <form autoComplete="off" onSubmit={(ev) => ev.preventDefault()}>
        <Fields.Checkbox
          name={getPathName("enabled")}
          onChange={(value) => onChange({ value, name: "enabled" })}
        >
          {t("Ubuntu Pro")}
          {fieldErrors[getPathName("enabled")] && renderErrorIcon()}
        </Fields.Checkbox>
        {renderInfoBox()}
        <Fields.Input
          data-qa="token"
          type="password"
          label={
            <>
              {t("Token")}
              {fieldErrors[getPathName("token")] ? renderErrorIcon() : null}
            </>
          }
          autocomplete="ubuntu-advantage-token"
          placeholder={t("Type here...")}
          name={getPathName("token")}
          onChange={(value) => onChange({ value, name: "token" })}
        />
        <SwitchFieldsWrap>
          {UBUNTU_ADVANTAGE_OPTIONS.map((option) => (
            <SwitchFieldWrap key={option.name}>
              <div>
                <span>{option.getLabel()}</span>
                {option.info ? (
                  <Tooltip title={option.info}>
                    <StyledInfoIcon awesome={faInfoCircle} />
                  </Tooltip>
                ) : null}
                {fieldErrors[getPathName(option.name)] && renderErrorIcon()}
              </div>
              <Fields.Switch
                data-qa={option.name}
                name={getPathName(option.name)}
                onChange={(value) => onChange({ value, name: option.name })}
                size="small"
                disabled={disabledFields.includes(option.name)}
              />
            </SwitchFieldWrap>
          ))}
        </SwitchFieldsWrap>
      </form>
    </UbuntuFormWrap>
  );
}
export const UbuntuProErrorSlot = createTeleporter();
export default function PresetsSider({
  presets = {},
  onChange,
  presetsPathName = "presets",
  disabledFields = [],
}) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const { fieldErrors } = useFormContext();

  if (presets.ubuntuAdvantage && !flags.has(FLAGS.UBUNTU_ADVANTAGE)) {
    return null;
  }

  const Trigger = (
    <StyledTrigger>
      <StyledIcon expanded={expanded}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </StyledIcon>
      <TriggerTitle>{t("Presets")}</TriggerTitle>
      {fieldErrors?.presetTittleError && !expanded && (
        <ErrorIcon awesome={faExclamationCircle} />
      )}
    </StyledTrigger>
  );

  return (
    <StyledSider
      collapsible
      collapsedWidth={170}
      width={300}
      trigger={Trigger}
      collapsed={!expanded}
      onCollapse={() => setExpanded(!expanded)}
      theme="light"
    >
      <FormWrap>
        <UbuntuProErrorSlot.Target />
        {Object.keys(presets).map((group) => {
          if (group === "ubuntuAdvantage") {
            return null;
          }
          return (
            <Group key={group}>
              <Fields.RadioGroup
                label={group}
                options={presets[group]}
                data-qa="presets"
                name={`${presetsPathName}.${group}`}
                onChange={(value) =>
                  onChange({ value, group, presets: presets[group] })
                }
              />
            </Group>
          );
        })}
        {presets.ubuntuAdvantage ? (
          <UbuntuAdvantageForm
            presetsPathName={presetsPathName}
            disabledFields={disabledFields}
            onChange={(props) =>
              onChange({
                ...props,
                group: "ubuntuAdvantage",
                presets: presets.ubuntuAdvantage,
              })
            }
          />
        ) : null}
      </FormWrap>
    </StyledSider>
  );
}
